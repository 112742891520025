import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Constants from "../helpers/constants";
import { isInThePast } from "../helpers/utils";

import {
  getSubscription,
  removeSubscription,
  disconnectLS,
  hasAutomatics,
  setHasAutomatics,
} from "../helpers/lsHelper";

import { chakra, Container, useDisclosure } from "@chakra-ui/react";

import axiosHelper from "../helpers/axiosHelper";
import { localizedDateFromString } from "../helpers/utils";

import MainLayout from "../layouts/MainLayout";

import HallHero from "../components/waitingroom/HallHero";
import CounterHall from "../components/waitingroom/CounterHall";
import VideoSection from "../components/waitingroom/VideoSection";
import QuestionModal from "../components/modal/QuestionModal";
import { useTranslation } from "react-i18next";

type Props = {
  showCountdownWR: boolean
};
const WaitingRoom = (props: Props) => {
  const { showCountdownWR } = props;
  const [eventStatus, setEventStatus] = useState<EventStatus>();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const history = useHistory();
  const [userProfile, setUserProfile] = useState<UserProfile>(
    axiosHelper.getUserProfile()!
  );
  const [eventDate, setEventDate] = useState<string>(Constants.EVENT_DATE);
  const [imageClass, setImageClass] = useState("redefine");
  const [showVideo, setShowVideo] = useState(false);
  const [showCountdown, setShowCountdown] = useState(false);
  const [targetDateString, setTargetDateString] = useState(
    "2020-12-01T10:30:00.000Z"
  );
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [currentVideo, setCurrentVideo] = useState<LibraryContent | null>(null);
  const [newestVideos, setNewestVideos] = useState<LibraryContent[]>([]);
  const [redflixCategories, setRedflixCategories] = useState<LibraryCategory[]>(
    []
  );
  const [currentQuestion, setCurrentQuestion] =
    useState<QuizQuestion | null>(null);
  const [prevQuestion, setPrevQuestion] =
    useState<QuizQuestion | null>(null);
  const [ranking, setRanking] = useState(0);
  const reloadUserProfile = () => {
    setUserProfile(axiosHelper.getUserProfile()!);
  }


  const fetchEventStatus = async () => {
    try {
      const result = await axiosHelper.getEventStatus();
      if (result != null) {
        console.log("###PLENARY### GOT THE EVENT STATUS");
        console.log(result);
        setEventStatus(result)
      }
    } catch {
      console.log("###PLENARY### COULD NOT FETCH EVENT STATUS");
    }
  };


  const handleEventStatus = () => {
    setEventDate(eventStatus!.showTime);
  }

  useEffect(() => {
    // console.log("USE EFFECT LOADING REDFLIX");
    fetchRedflix();
  }, [t("LNG"), userProfile]);


  const questionHasClosed = () => {
    // console.log("question has closed");
    onClose();
    setCurrentQuestion(null);
    fetchRanking();
    fetchQuiz();
  };

  const fetchRanking = async () => {
    try {
      const result = await axiosHelper.getRank();
      if (result != null) {
        setRanking(result.rank);
      }
    } catch {
      // console.log("no ");
    }
  };

  const fetchQuiz = async () => {
    try {
      const result = await axiosHelper.getQuiz();
      if (result != null) {
        var myQuiz: Quiz = result;
        for (let element of myQuiz.quizQuestionsSorted) {
          if (!element.answered && !element.forEvent) {
            if (prevQuestion != element) {
              setPrevQuestion(element);
              setCurrentQuestion(element);
              onOpen();
              break;
            }
          }
        }
        console.log(result);
      }
    } catch {
      console.log("no quiz for me");
    }
  };

  const fetchRedflix = async () => {
    try {
      const rawResult = await axiosHelper.getRedflixCategories();
      if (rawResult != null) {
        // console.log("GOT THE VIDEOS LIST", result);
        var lv: LibraryContent[] = [];
        var keys: number[] = [];
        var result: LibraryCategory[] = [];
        rawResult.forEach(function (item) {
          if (keys.indexOf(item.id) < 0) {
            result.push(item);
            keys.push(item.id);
          }
        });

        result.forEach(function (libCat) {
          var filtered: LibraryContent[] = [];
          filtered = libCat.filteredItems.filter((item) => {
            return item.isNew;
          });
          lv = lv.concat(filtered);
        });
        // refNewest.current = lv.sort((a, b) => (a.availableFrom! < b.availableFrom! ? -1 : 1));

        var newest: LibraryContent[] = [];
        var newestAvailable = lv.filter((item) => {
          return isInThePast(new Date(Date.parse(item.availableFrom!)));
        });
        console.log('newestAvailable = ' + newestAvailable.length);
        var newestUnavailable = lv.filter((item) => {
          return !isInThePast(new Date(Date.parse(item.availableFrom!)));
        });
        console.log('newestUnavailable = ' + newestUnavailable.length);
        newest = newest.concat(
          newestAvailable.sort((a, b) => (a.availableFrom! > b.availableFrom! ? -1 : 1))
        );
        newest = newest.concat(
          newestUnavailable.sort((a, b) => (a.availableFrom! > b.availableFrom! ? -1 : 1))
        );
        console.log('newest = ' + newest.length);

        setNewestVideos(newest);

        setRedflixCategories(result);
      }
    } catch {
      // console.log('ERRORE NEL TIRAR GIU I DATI');
    }
  };

  const parseStatus = (statusString: string | null) => {
    if (statusString !== null) {
      axiosHelper.setCachedStatusForHall(statusString);
      var splitted = statusString.split("|");
      // console.log(splitted);
      setImageClass(splitted[0]);
      setShowVideo(JSON.parse(splitted[1]));
      setShowCountdown(JSON.parse(splitted[2]));
      setTargetDateString(splitted[3]);
    }
  };

  const saveUserProfile = async (data: UserProfile) => {
    try {
      const result = await axiosHelper.updateAccount(data);
      axiosHelper.saveUserProfile(result.data);
      setUserProfile(result.data);
      history.push("/welcome");
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    axiosHelper.logWebAppEvent("WaitingRoom", null, null);

    if (userProfile.firstLogin) {
      if ('RSI'.includes(userProfile.userType)) {
        history.push("/tutorial");
      } else {
        history.push("/cluster");
      }
    } else if (userProfile.field2) {
      history.push("/plenaryCluster");
    }

    if ('RSI'.includes(userProfile.userType)) {
      fetchQuiz();
      fetchRanking();
    }

    window.scrollTo(0, 0);
    return () => {
      // console.log('HALL VIA EFFECT');
      // removeSubscription(mySubscription);
    };
  }, [userProfile, history]);

  useEffect(() => {
    if (eventStatus) {
      handleEventStatus();
    } else {
      fetchEventStatus();
    }
  }, [eventStatus])

  return (
    <MainLayout home coppetta={ranking} reloadUserProfile={reloadUserProfile}>
      <HallHero
        usertype={userProfile.userType}
        videoList={newestVideos}
        dateString={localizedDateFromString(eventDate, t("LNG"))}
      />
      {showCountdownWR ?
        <CounterHall
          endDateString={eventDate}
          usertype={userProfile.userType}
          ranking={ranking}
        />
        :
        null}
      {redflixCategories.map((category, index) => (
        <VideoSection
          videoList={category?.filteredItems}
          title={category?.nameLocalized}
          key={index}
          padding="20px"
        />
      ))}
      {currentQuestion != null && (
        <QuestionModal
          clickOpen={isOpen}
          clickClose={questionHasClosed}
          qq={currentQuestion}
        />
      )}
    </MainLayout>
  );
};

export default WaitingRoom;
