import React, { useEffect, useState, useRef } from "react";
import PlenaryChatMessage from "./PlenaryChatMessage";

import axiosHelper from "../../helpers/axiosHelper";
import { getSubscription, removeSubscription } from "../../helpers/lsHelper";
import { useTranslation } from "react-i18next";
import sendIcon from "../../assets/plenary/send.svg";
import emojiTriggerOff from "../../assets/plenary/emoji-trigger-off.svg";
import emojiTriggerOn from "../../assets/plenary/emoji-trigger-on.svg";
import emoji1 from "../../assets/plenary/emoji-1.svg";
import emoji2 from "../../assets/plenary/emoji-2.svg";
import emoji3 from "../../assets/plenary/emoji-3.svg";
import emoji4 from "../../assets/plenary/emoji-4.svg";
import emoji5 from "../../assets/plenary/emoji-5.svg";
import reactionsHelper from "../../helpers/reactionsHelper";
import { useForm } from "react-hook-form";

import {
  Flex,
  Input,
  Button,
  useBreakpointValue,
  Image,
} from "@chakra-ui/react";
import { Subscription } from "lightstreamer-client-web";

type ChatMessageData = {
  messageText: string;
};

type Props = {
  eventStatus: EventStatus;
};
const PlenaryChat = (props: Props) => {
  const { eventStatus } = props;
  const { t } = useTranslation();
  let lastLSNotification: string | null = null;
  const [noAutoScroll] = useState<boolean>(false);
  const [useAutoScroll, setUseAutoScroll] = useState(true);
  const [userProfile] = useState<UserProfile>(axiosHelper.getUserProfile()!);
  const [chatRoom, setChatRoom] = useState<ChatRoom>();
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const [emojiPickerOpened, setEmojiPickerOpened] = useState<boolean>(false);
  const [canRead, setCanRead] = useState<boolean>(true);
  const [canWrite, setCanWrite] = useState<boolean>(true);
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const { handleSubmit, register } = useForm<FormData>();

  const chatRooms = useRef<ChatRoom[]>();
  const currentChatRoom = useRef<ChatRoom>();
  //Scrolla all'ultimo messaggio
  const bottomEl = useRef<HTMLDivElement>(null);
  const lastMessageGot = useRef(0);
  const waitforit = useRef(true);

  const isCustomer = () => {
    return "RSI".includes(userProfile.userType);
  };

  useEffect(() => {
    if (isCustomer()) {
      reactionsHelper.setContainerName("reactionsContainer");
    }
  }, []);

  useEffect(() => {
    console.log("PLENARY CHAT USE EFFECT");
    window.scrollTo(0, 0);
    let mySubscription1: Subscription;
    let mySubscription2: Subscription;
    let mySubscription3: Subscription;

    if (chatRoom != null) {
      console.log("HAVE A CHATROOM " + chatRoom.id);
      mySubscription1 = getSubscription(
        "CHATS_IN_" + chatRoom.id,
        (message: string) => {
          if (message != null && !waitforit.current) {
            fetchIfNecessary(Number(message));
          }
        }
      );
      mySubscription2 = getSubscription(
        "CHATS_DEL_" + chatRoom.id,
        (message: string) => {
          if (message != null && !waitforit.current) {
            handleDeleteNotification(Number(message));
          }
        }
      );
      mySubscription3 = getSubscription("USERBAN", (message: string) => {
        if (message != null && !waitforit.current) {
          handleBanNotification(Number(message));
        }
      });
    } else {
      console.log("NO SELECTED CHATROOM");
      waitforit.current = true;
      fetchRooms();
    }
    return () => {
      console.log("PLENARY CHAT VIA EFFECT");
      if (mySubscription1 != null) {
        console.log("UNSUBSCRIBING 1");
        removeSubscription(mySubscription1);
      } else {
        console.log("NOTHING TO UNSUBSCRIBE TO 1");
      }
      if (mySubscription2 != null) {
        console.log("UNSUBSCRIBING 2");
        removeSubscription(mySubscription2);
      } else {
        console.log("NOTHING TO UNSUBSCRIBE TO 2");
      }

      if (mySubscription3 != null) {
        console.log("UNSUBSCRIBING 3");
        removeSubscription(mySubscription3);
      } else {
        console.log("NOTHING TO UNSUBSCRIBE TO 3");
      }
    };
  }, [chatRoom]);

  const chiamaAnima = (icon: number) => {
    reactionsHelper.anima(icon);
    setEmojiPickerOpened(false);

    setTimeout(() => {
      reactionsHelper.setMultiplier(800);
    }, 500);
    setTimeout(() => {
      reactionsHelper.setMultiplier(10000);
    }, 5000);
  };
  useEffect(() => {
    console.log("MAYBE LANGUAGE DID CHANGE");
    pickTheRightChatRoom();
  }, [t("LNG")]);

  const fetchIfNecessary = (msgId: number) => {
    console.log("FETCH IF NECESSARY " + msgId + " " + lastMessageGot.current);
    if (msgId > lastMessageGot.current) {
      fetchMessages(true);
    } else {
      console.log("FetchMessages was not necessary...");
    }
  };

  const handleDeleteNotification = (messageid: number) => {
    setMessages((messages) =>
      messages.filter((msg) => {
        return msg.id !== messageid;
      })
    );
  };

  const handleBanNotification = (userid: number) => {
    setMessages((messages) =>
      messages.filter((msg) => {
        return msg.idsender !== userid;
      })
    );
  };

  const fetchRooms = async () => {
    try {
      const result = await axiosHelper.getChatRooms();
      if (result != null) {
        chatRooms.current = result;
        console.log("GOT THE CHATROOMS");
        console.log(result);
        pickTheRightChatRoom();
      }
    } catch {
      // console.log('ERRORE NEL TIRAR GIU I DATI');
    }
  };

  const pickTheRightChatRoom = () => {
    if (chatRooms.current != null) {
      console.log("PICKING FROM THE ROOMS LIST " + chatRooms.current.length);
      let foundIt = false;
      //FIXME
      //TODO
      //Mettere qua un meccanismo di selezione delle chat furbo
      for (let i = 0; i < chatRooms.current.length; i++) {
        if (
          chatRooms.current[i].userTypesWrite?.includes(userProfile.userType) &&
          chatRooms.current[i].lang === t("LNG") &&
          chatRooms.current[i].event?.id === eventStatus.idEvent
        ) {
          currentChatRoom.current = chatRooms.current[i];
          setChatRoom(chatRooms.current[i]);
          foundIt = true;
          console.log("found it");
          setCanWrite(true);
          waitforit.current = true;
          lastMessageGot.current = 0;
          fetchMessages(false);
          break;
        }
      }
      if (!foundIt) {
        for (let i = 0; i < chatRooms.current.length; i++) {
          if (
            chatRooms.current[i].userTypesRead?.includes(
              userProfile.userType
            ) &&
            chatRooms.current[i].lang === t("LNG") &&
            chatRooms.current[i].event?.id === eventStatus.idEvent
          ) {
            currentChatRoom.current = chatRooms.current[i];
            setChatRoom(chatRooms.current[i]);
            foundIt = true;
            console.log("found it just listening");
            setCanWrite(false);
            waitforit.current = true;
            lastMessageGot.current = 0;
            fetchMessages(false);
            break;
          }
        }
      }
      // setCurrentChatRoom(result.filter((cr) => cr.userTypesRead?.includes(userProfile.userType))[0])
    }
  };

  const fetchMessages = async (keepOld: boolean) => {
    console.log("FETCH MESSAGES " + keepOld);
    console.log(currentChatRoom.current);

    try {
      if (currentChatRoom.current != null) {
        const result = await axiosHelper.getChatMessages(
          currentChatRoom.current?.id,
          lastMessageGot.current
        );
        if (result != null) {
          if (keepOld) {
            setMessages((messages) => [...messages, ...result.reverse()]);
          } else {
            setMessages([...result.reverse()]);
          }
          waitforit.current = false;
          if (result.length > 0) {
            lastMessageGot.current = result.reverse()[0].id;
          }
          // setTimeout(() => {
          if (bottomEl.current !== null) {
            const parent = bottomEl.current.parentNode as HTMLDivElement;
            parent.scrollTop = bottomEl.current.offsetTop;
            // if (useAutoScroll) {
            //   console.log('autoscroll');
            //   console.log(bottomEl.current);
            //   bottomEl.current.scrollIntoView({ behavior: "auto" })
            //   setUseAutoScroll(false)
            // } else {
            //   console.log('no autoscroll');
            //   console.log(bottomEl.current);
            //   bottomEl.current.scrollIntoView({ block: "end", inline: "nearest", behavior: "smooth" })
            // }
          } else {
            console.log("bottomEl null");
          }
          // setTimeout(() => {
          //   // window.scrollTo(0, 0);
          // }, 100);
        }
      } else {
        console.log("no current chat room");
      }
    } catch (exception) {
      console.log("ERRORE NEL DOWNLOAD DEI DATI");
      console.log(exception);
    }
  };

  const sendMessage = (values: { messageText: string }, e: any) => {
    console.log(values);
    console.log("SENDING MESSAGE " + values.messageText);
    const saveMessage = async (message: string) => {
      try {
        console.log("SENDING MESSAGE");
        const result = await axiosHelper.sendMessageToRoom(
          message,
          currentChatRoom.current!.id
        );
        console.log(result.data);
        // fetchMessages()
        // console.log('faccio set Messages');

        // lastMessageGot.current = result.data.id
        // setMessages(messages=>[...messages, result.data]);
        // console.log(messages);
      } catch (error) {}
    };
    if (values.messageText) {
      saveMessage(values.messageText);
      e.target.reset();
    }
  };

  const calculatedBackground = () => {
    if (isMobile) {
      return "rgba(255, 255, 255, 0.1)";
    }
    if (isCustomer()) {
      return "rgba(255, 255, 255, 0.1)";
    }
    return "#1a1a1a";
  };

  return (
    <>
      {canRead && (
        <Flex
          d="flex"
          flexDir="column"
          position="relative"
          justifyContent="stretch"
          w={isMobile ? "100%" : "30%"}
          bgColor={calculatedBackground()}
          boxShadow="0px 3px 6px #00000029"
          p="0"
          className="messages-wrapper"
          overflow="hidden"
        >
          <Flex
            p=".7rem .3rem 0 .3rem"
            color="white"
            flexGrow={1}
            overflow="hidden"
            d="flex"
            flex="1"
            minH="1"
            pr=".1rem"
          >
            <Flex
              w="100%"
              height="100%"
              maxHeight="100%"
              d="flex"
              flexDir="column"
            >
              <Flex
                w="100%"
                h={isMobile ? "300px" : "0"}
                minHeight="0"
                overflow="auto"
                flexGrow={1}
                flexDir="column"
                className="smal-scrollbar-light"
                pr=".1rem"
                id="reactionsContainer"
              >
                {messages?.map((message) => (
                  <PlenaryChatMessage
                    key={message.id}
                    message={message}
                    isMine={message.idsender === userProfile.id}
                  />
                ))}

                <div id="scrollph" ref={bottomEl}></div>
              </Flex>
            </Flex>
          </Flex>
          {canWrite ? (
            <Flex
              bgColor="#707070"
              p="0.5rem"
              height="calc(40px + 1rem)"
              className="message-edit-wrapper"
              position="relative"
            >
              <form
                id="messageForm"
                className="col-12 d-flex flex-row justify-content-between align-items-stretch"
                onSubmit={handleSubmit(sendMessage)}
                autoComplete="off"
              >
                <Input
                  placeholder={t("plenary.chat.placeholder")}
                  bgColor="#eee"
                  boxShadow="inset 0px 2px 0px #00000029"
                  borderRadius="0"
                  border="0"
                  variant="outline"
                  flexGrow={1}
                  fontSize="12px"
                  padding=".5rem 2rem .5rem .5rem"
                  height="40px"
                  minH="40px"
                  mr=".5rem"
                  form="messageForm"
                  name="messageText"
                  ref={register}
                />
                <Button
                  bgColor="#C41230"
                  borderRadius="0"
                  color="white"
                  display="block"
                  w="40px"
                  minW="40px"
                  h="40px"
                  p=".5rem"
                  type="submit"
                >
                  <img src={sendIcon} alt="Send" />
                </Button>
              </form>
              {isCustomer() ? (
                <Image
                  src={emojiPickerOpened ? emojiTriggerOn : emojiTriggerOff}
                  alt="Emoji"
                  position="absolute"
                  top="1rem"
                  right="calc(40px + 1.5rem)"
                  zIndex="1"
                  display="block"
                  width="20px"
                  height="19px"
                  cursor="pointer"
                  onClick={() => {
                    setEmojiPickerOpened(!emojiPickerOpened);
                  }}
                  _hover={{}}
                />
              ) : null}
              {emojiPickerOpened && (
                <Flex
                  bgColor="white"
                  display="flex"
                  justifyContent="space-between"
                  alignItems="baseline"
                  position="absolute"
                  top="-50px"
                  left="0"
                  width="100%"
                  height="50px"
                >
                  <Image
                    src={emoji1}
                    alt=""
                    m="0.3rem 0.6rem"
                    cursor="pointer"
                    transition="all .2s ease-in-out"
                    _hover={{
                      transform: "scale(1.1)",
                      className: "reaction1",
                    }}
                    onClick={() => chiamaAnima(1)}
                  />
                  <Image
                    src={emoji2}
                    alt=""
                    m="0.4rem 0.6rem"
                    cursor="pointer"
                    transition="all .2s ease-in-out"
                    _hover={{ transform: "scale(1.1)", className: "reaction2" }}
                    onClick={() => chiamaAnima(2)}
                  />
                  <Image
                    src={emoji3}
                    alt=""
                    m="0.4rem 0.6rem"
                    cursor="pointer"
                    transition="all .2s ease-in-out"
                    _hover={{ transform: "scale(1.1)", className: "reaction3" }}
                    onClick={() => chiamaAnima(3)}
                  />
                  <Image
                    src={emoji4}
                    alt=""
                    m="0.4rem 0.6rem"
                    cursor="pointer"
                    transition="all .2s ease-in-out"
                    _hover={{ transform: "scale(1.1)", className: "reaction4" }}
                    onClick={() => chiamaAnima(4)}
                  />
                  <Image
                    src={emoji5}
                    alt=""
                    m="0.3rem 0.6rem"
                    cursor="pointer"
                    transition="all .2s ease-in-out"
                    _hover={{ transform: "scale(1.1)", className: "reaction5" }}
                    onClick={() => chiamaAnima(5)}
                  />
                </Flex>
              )}
            </Flex>
          ) : (
            <>
              <Flex
                bgColor="#707070"
                p="0.5rem"
                height="calc(40px + 1rem)"
                className="message-edit-wrapper"
                position="relative"
                color="white"
                alignItems="center"
                fontWeight="bold"
                justifyContent="center"
              >
                {t("plenary.chat.message")}
              </Flex>
            </>
          )}
        </Flex>
      )}
    </>
  );
};

export default PlenaryChat;
